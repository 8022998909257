import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCB4GsgQ_q4oR1yd0e8YgK10GgvMCAFH_A',
  authDomain: 'lucianomogorovic-dev.firebaseapp.com',
  projectId: 'lucianomogorovic-dev',
  storageBucket: 'lucianomogorovic-dev.firebasestorage.app',
  messagingSenderId: '128240945110',
  appId: '1:128240945110:web:37a7798e83b2efb6114677',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
