const ExperienceSkeleton = (props) => {
  const details = [];
  for (let i = 0; i < props.numDetails; i++) {
    details.push(<li key={i} className='skeleton skeleton-text detail'></li>);
  }

  return (
    <div className='exp relative mb-[30px] leading-none'>
      <div className='uppercase font-bold text-[18px] skeleton skeleton-text title'></div>
      <div className='company text-[13px] mb-[15px]'>
        <div className='skeleton skeleton-text half'></div>
        <div className='skeleton skeleton-text half'></div>
      </div>
      <br />
      <ul className='text-[13px] pl-[16px] list-arrow'>
        {details.map((detail) => {
          return detail;
        })}
      </ul>
    </div>
  );
};

export default ExperienceSkeleton;
