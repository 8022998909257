// import logo from './logo.svg';
import { useEffect, useState } from 'react';
import './App.scss';
import { db } from './firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import ExperienceSkeleton from './components/ExperienceSkeleton';

function App() {
  async function getExps() {
    const experiencesCol = collection(db, 'experiences');
    const experiencesSnapshot = await getDocs(experiencesCol);
    const experiencesList = experiencesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return experiencesList.sort((exp1, exp2) => {
      return exp2.startYear - exp1.startYear;
    });
  }

  const [exps, setExps] = useState([]);

  useEffect(() => {
    getExps()
      .then((data) => {
        setExps(data);
      })
      .catch((e) => console.error('Error getting experience:', e));
  }, []);

  return (
    <div className='App font-gothic'>
      <div className='App-header mx-[auto] my-[30px] rounded-[30px] flex flex-col-reverse lg:flex-row min-h-[80px] max-w-[85vw] sm:max-w-[65vw] border-[1px] border-[#122842]'>
        <div className='narrow-column bg-[#122842] px-[30px] pt-[45px] rounded-br-[27px] rounded-bl-[27px] lg:rounded-tl-[27px] lg:rounded-br-[initial]'>
          <div className='narrow-section contact mb-[40px]'>
            <h3 className='uppercase text-[#F19D38] text-[15px] font-extrabold my-[7px]'>
              Contact
            </h3>
            <p className='link text-[13px] text-white my-[5px] hover:italic hover:font-bold'>
              <a
                className='email-link'
                href='mailto:luciano.mogorovic@gmail.com'
                target='_blank'
                rel='noreferrer noopener'
              >
                ✉️&nbsp;luciano.mogorovic@gmail.com
              </a>
            </p>
            <p className='link text-[13px] text-white my-[5px] hover:italic hover:font-bold'>
              <a
                className='site-link'
                href='https://lucianomogorovic.dev/'
                target='_blank'
                rel='noreferrer noopener'
              >
                💻&nbsp;lucianomogorovic.dev
              </a>
            </p>
            <p className='link text-[13px] text-white my-[5px] hover:italic hover:font-bold'>
              <a
                className='phone-link'
                href='tel:2166591931'
                target='_blank'
                rel='noreferrer noopener'
              >
                📞&nbsp;(216) 659-1931
              </a>
            </p>
            <p className='link text-[13px] text-white my-[5px] hover:italic hover:font-bold'>
              <a
                className='map-link'
                href='https://goo.gl/maps/QBJpWYupdM12hRhe9'
                target='_blank'
                rel='noreferrer noopener'
              >
                📍&nbsp;Cleveland, OH
              </a>
            </p>
          </div>
          <div className='narrow-section mb-[40px]'>
            <h3 className='uppercase text-[#F19D38] text-[15px] font-extrabold my-[7px]'>
              Education
            </h3>
            <p className='school-name text-[13px] text-white font-bold uppercase'>
              University of Akron
            </p>
            <p className='school-desc text-[13px] text-white'>
              BS, Computer Science
            </p>
            <p className='school-desc text-[13px] text-white'>2018</p>
          </div>
          <div className='narrow-section skills mb-[40px]'>
            <h3 className='uppercase text-[#F19D38] text-[15px] font-extrabold my-[7px]'>
              Skills
            </h3>
            <ul className='text-white text-[13px] pl-[16px] list-check'>
              <li className='mb-[7px]'>JavaScript</li>
              <li className='mb-[7px]'>TypeScript</li>
              <li className='mb-[7px]'>HTML</li>
              <li className='mb-[7px]'>CSS</li>
              <li className='mb-[7px]'>SCSS</li>
              <li className='mb-[7px]'>PHP</li>
              <li className='mb-[7px]'>Node.js</li>
              <li className='mb-[7px]'>React</li>
              <li className='mb-[7px]'>Redux</li>
              <li className='mb-[7px]'>Angular</li>
              <li className='mb-[7px]'>WordPress</li>
              <li className='mb-[7px]'>Craft CMS</li>
              <li className='mb-[7px]'>C#</li>
              <li className='mb-[7px]'>SQL</li>
              <li className='mb-[7px]'>.NET Core</li>
              <li className='mb-[7px]'>.NET Framework</li>
            </ul>
          </div>
        </div>

        <div className='main-column px-[30px]'>
          <div className='name-section mb-[30px]'>
            <h1 className='text-[53px] font-extrabold mt-[45px] mb-[15px] leading-none md:text-[64px]'>
              Luciano
              <br />
              Mogorovic
            </h1>
            <span className='uppercase text-[20px] font-bold'>
              Software Developer
            </span>
          </div>
          {exps.length ? (
            <div className='experience-section'>
              <h2 className='exp-header uppercase font-extrabold text-2xl mb-[25px]'>
                Experience
              </h2>
              {exps.map((exp) => {
                return (
                  <div className='exp relative mb-[30px] leading-none'>
                    <h3 className='uppercase font-bold text-[18px]'>
                      {exp.title}
                    </h3>
                    <p className='company text-[13px] mb-[15px]'>
                      <i className='uppercase'>{exp.company}</i>
                      <br />
                      {exp.duration}
                    </p>
                    <ul className='text-[13px] pl-[16px] list-arrow'>
                      {exp.details.map((detail) => {
                        return <li className='mb-[7px]'>{detail}</li>;
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='experience-section'>
              <h2 className='exp-header uppercase font-extrabold text-2xl mb-[25px]'>
                Experience
              </h2>
              <ExperienceSkeleton numDetails={0} />
              <ExperienceSkeleton numDetails={6} />
              <ExperienceSkeleton numDetails={6} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
